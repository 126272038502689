import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import { Grid, Box, Typography, Button, Card, CardMedia, CardContent, Container, CardActions } from '@mui/material';

const AnnadanamPage = () => {

    const otherCauses = [
        {
            image: 'https://picsum.photos/id/257/200/300',
            title: 'Support Patashalas',
            description: 'The Patashala specializes in teaching the ancient Indian traditions...',
            link: 'support-patashalas',
        },
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Agnihothra Vruddhi',
            description: 'Agni is the Lord of fire who devours and purifies. He helps us in our...',
            link: 'agnihotra-vruddhi',
        },
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Village Parayanam',
            description: 'Agni is the Lord of fire who devours and purifies...',
            link: 'village-temple-parayanam',
        },
    ];

    return (
        <div>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />

                <Container sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Grid container spacing={4}>
                        {/* Main Content */}
                        <Grid item xs={12} md={8}>
                            {/* Image Section */}
                            <Box sx={{ mb: 4 }}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="400"
                                        image='https://img.freepik.com/free-photo/majestic-mountain-peak-tranquil-winter-landscape-generated-by-ai_188544-15662.jpg'
                                        alt="Annadanam"
                                        sx={{ objectFit: 'cover' }}
                                    />
                                </Card>
                            </Box>

                            {/* Text Section */}
                            <Box sx={{ p: 1 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Annadanam:
                                </Typography>
                                <Typography paragraph>
                                    “Annadbhavanthibhootani” – These are the words of Sri Krishna in Bhagvat Gita. It means that all creation is sustained due to food. Whatever beings exist on earth, they are all born out of food. By food alone they remain alive and to that they return in the end.
                                </Typography>
                                <Typography paragraph>
                                    Annadanam plays a vital role in any dharmic activity. In our tradition, the importance given to annadanam is immense and is considered to be very sacred. Helping to alleviate a person’s hunger is a deed that brings good karma in this life and beyond.
                                </Typography>
                                <Typography paragraph>
                                    The trustees of Goveda Foundation and their friends have been performing Annadanam during the Arubathumoovar festival at Mylapore for almost a decade.
                                </Typography>
                                <Typography paragraph>People from all walks of life join together to participate in this Dharmic activity.
                                </Typography>
                                <Box sx={{ marginY: 3 }}>
                                    <Button variant="contained" sx={{ color: "white" }} href="/donate">
                                        Donate Now
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Sidebar */}
                        <Grid item xs={12} md={4} >
                            <Typography variant="h5" gutterBottom>
                                Other Causes
                            </Typography>
                            {otherCauses.map((cause, index) => (
                                <Card sx={{ mb: 4 }} key={index}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={cause.image}
                                        alt={cause.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">
                                            <a href={cause.link} style={{ textDecoration: 'none', color: '#000' }}>
                                                {cause.title}
                                            </a>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {cause.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant='contained' href={cause.link} sx={{
                                            color: "white",
                                        }}>Read More</Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default AnnadanamPage;
