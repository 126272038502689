import React from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                py: 4,
                textAlign: 'center',
                backgroundColor: '#323d38',
                color: "white",
                mt: 4,
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 4 }}>
                <img
                    src={require("../images/goveda-logo.png")}
                    alt="GoVeda Foundation Logo"
                    style={{ width: '150px', borderRadius: '10px', marginBottom: '16px' }} // Rounded corners for logo
                />
                <Typography variant="body2" sx={{ fontStyle: 'italic', mb: 2 }}>
                    Your trusted partner for spiritual wellness and community support.
                </Typography>
            </Box>

            <Grid container spacing={2} sx={{ textAlign: { xs: 'left', sm: 'center' }, pl: 2 }}> {/* Align text based on screen size */}
                <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="h6" sx={{ mb: 1, color: "rgb(16, 203, 127)" }}>Quick Links</Typography>
                    <Link href="/" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        Home
                    </Link>
                    <Link href="about" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        About Us
                    </Link>
                    <Link href="privacy-policy" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        Privacy Policy
                    </Link>
                    <Link href="termsandconditions" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        Terms and Conditions
                    </Link>
                    <Link href="cancellation-refund" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        Cancellation and Refund
                    </Link>
                    <Link href="shipping-delivery" color="inherit" underline="hover" sx={{ display: 'block', mb: 1 }}>
                        Shipping and Delivery
                    </Link>
                    <Link href="contact" color="inherit" underline="hover" sx={{ display: 'block' }}>
                        Contact Us
                    </Link>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Typography variant="h6" sx={{ mb: 1, color: "rgb(16, 203, 127)" }}>Contact Us</Typography>
                    <Typography variant="body2" sx={{ mb: 1 }}>
                        Email: info@govedafoundation.org
                    </Typography>
                    <Typography variant="body2">
                        Phone: +91 7825807830
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4} >
                    <Typography variant="h6" sx={{ mb: 1, color: "rgb(16, 203, 127)" }}>Follow Us</Typography>
                    <Box sx={{ display: 'flex', justifyContent: { xs: "start", md: "center" }, gap: 3, mt: 1 }}>
                        <Link href="#" color="inherit" aria-label="Facebook">
                            <Facebook sx={{ fontSize: 30 }} />
                        </Link>
                        <Link href="#" color="inherit" aria-label="Twitter">
                            <Twitter sx={{ fontSize: 30 }} />
                        </Link>
                        <Link href="#" color="inherit" aria-label="Instagram">
                            <Instagram sx={{ fontSize: 30 }} />
                        </Link>
                        <Link href="#" color="inherit" aria-label="LinkedIn">
                            <LinkedIn sx={{ fontSize: 30 }} />
                        </Link>
                    </Box>
                </Grid>
            </Grid>

            <Typography variant="body2" sx={{ mb: 2, marginTop: '20px' }}>
                Copyright © 2017-2024 <span style={{ color: '#10cb7f' }}>GoVeda Foundation</span>. All rights reserved.
            </Typography>
        </Box>
    );
};

export default Footer;
