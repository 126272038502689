import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import {
    Box,
    Typography,
    Grid,
    Paper,
    Container,
    IconButton,
    Modal,
    Tabs,
    Tab
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const images = {
    all: [
        { src: 'https://picsum.photos/200/300?random=4', alt: 'Cause Image 1' },
        { src: 'https://picsum.photos/200/300?random=5', alt: 'Cause Image 2' },
        { src: 'https://picsum.photos/200/300?random=6', alt: 'Cause Image 3' },
        { src: 'https://picsum.photos/200/300?random=7', alt: 'Event Image 1' },
        { src: 'https://picsum.photos/200/300?random=8', alt: 'Event Image 2' },
        { src: 'https://picsum.photos/200/300?random=9', alt: 'Event Image 3' },
    ],
    causes: [
        { src: 'https://picsum.photos/200/300?random=4', alt: 'Cause Image 1' },
        { src: 'https://picsum.photos/200/300?random=5', alt: 'Cause Image 2' },
        { src: 'https://picsum.photos/200/300?random=6', alt: 'Cause Image 3' },
    ],
    events: [
        { src: 'https://picsum.photos/200/300?random=7', alt: 'Event Image 1' },
        { src: 'https://picsum.photos/200/300?random=8', alt: 'Event Image 2' },
        { src: 'https://picsum.photos/200/300?random=9', alt: 'Event Image 3' },
    ],
};

const Gallery = () => {


    const [tabIndex, setTabIndex] = useState(0);
    const [openModal, setOpenModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleImageClick = (image) => {
        setSelectedImage(image);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setSelectedImage(null);
    };

    // Function to handle key presses
    const handleKeyPress = (event) => {
        if (openModal) {
            const currentIndex = images[Object.keys(images)[tabIndex]].findIndex(img => img.src === selectedImage);
            if (event.key === 'ArrowRight') {
                // Show next image
                const nextIndex = (currentIndex + 1) % images[Object.keys(images)[tabIndex]].length;
                setSelectedImage(images[Object.keys(images)[tabIndex]][nextIndex].src);
            } else if (event.key === 'ArrowLeft') {
                // Show previous image
                const prevIndex = (currentIndex - 1 + images[Object.keys(images)[tabIndex]].length) % images[Object.keys(images)[tabIndex]].length;
                setSelectedImage(images[Object.keys(images)[tabIndex]][prevIndex].src);
            }
        }
    };

    // Effect to add and remove the keydown event listener
    useEffect(() => {
        if (openModal) {
            window.addEventListener('keydown', handleKeyPress);
        } else {
            window.removeEventListener('keydown', handleKeyPress);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, [openModal, selectedImage, tabIndex]);

    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container sx={{ mt: 2 }}>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEu6-A0N8KC3QPP_oBiuqa-JVic3tU90M_vnP6YvzoFNAGlfZOlLhS_hmr3cfQzmYw6A&usqp=CAU' width="100%" height={150} />
            </Container>
            <Container>
                <Box my={4}>
                    <Typography variant="h4" gutterBottom sx={{ textAlign: "center" }}>
                        Gallery
                    </Typography>

                    <Tabs value={tabIndex} onChange={handleTabChange} aria-label="gallery tabs">
                        <Tab label="All" />
                        <Tab label="Causes" />
                        <Tab label="Events" />
                    </Tabs>

                    <Grid container spacing={2} mt={2}>
                        {images[Object.keys(images)[tabIndex]].map((image, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Paper
                                    onClick={() => handleImageClick(image.src)}
                                    sx={{
                                        cursor: 'pointer',
                                        position: 'relative',
                                        overflow: 'hidden',
                                        borderRadius: 1,
                                        '&:hover': {
                                            boxShadow: 3,
                                        },
                                    }}
                                >
                                    <img
                                        src={image.src}
                                        alt={image.alt}
                                        style={{
                                            width: '100%',
                                            height: '200px',
                                            objectFit: 'cover',
                                        }}
                                    />
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Modal for Image View */}
                    <Modal
                        open={openModal}
                        onClose={handleCloseModal}
                        aria-labelledby="image-modal-title"
                        aria-describedby="image-modal-description"
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '100%',
                                bgcolor: 'rgba(0, 0, 0, 0.8)',
                                padding: 2,
                            }}
                            onClick={handleCloseModal}
                        >
                            <IconButton
                                aria-label="close"
                                onClick={handleCloseModal}
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    right: 16,
                                    color: 'white',
                                }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <img
                                src={selectedImage}
                                alt="Selected"
                                style={{
                                    maxWidth: '90%',
                                    maxHeight: '90%',
                                    borderRadius: '8px',
                                }}
                            />
                        </Box>
                    </Modal>
                </Box>
            </Container>

            <Footer />
        </ThemeProvider>
    );
};

export default Gallery;
