import Phone from "@mui/icons-material/Phone";
import { Box, Button, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import apiUrl from "../Api/api";
import axios from "axios";
import withAuth from "../HighOrderComponent/withAuth"; // Import the HOC

const DashboardLogin = ({ loading, error, handleLoading, handleError }) => {


    const [isOtpVisible, setIsOtpVisible] = useState(false); // State to toggle OTP visibility
    const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number
    const [otp, setOtp] = useState(['', '', '', '']); // State for OTP

    const handleSubmitPhone = async () => {
        const trimmedPhoneNumber = phoneNumber.trim(); // Trim the phone number

        if (!trimmedPhoneNumber) {
            handleError('Phone number is required.'); // Use HOC error handling
            return;
        }

        handleError('');

        try {
            handleLoading(true);

            const url = `${apiUrl}/getuser/credentials?phoneNumber=${phoneNumber}`;
            const response = await axios.get(url);

            setIsOtpVisible(true);

            handleLoading(false);
        } catch (error) {
            handleError(error.response.data.message);
            handleLoading(false);
        }

    };

    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;

        // Move focus to the next input if the current one is filled
        if (value && index < 3) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            if (nextInput) nextInput.focus();
        }

        // Move focus back if the input is empty
        if (!value && index > 0) {
            const prevInput = document.getElementById(`otp-input-${index - 1}`);
            if (prevInput) prevInput.focus();
        }

        setOtp(newOtp);
    };

    const handleVerifyOtp = async () => {

        if (otp.some(digit => digit === '')) {
            handleError('Please fill in all OTP fields.'); // Use HOC error handling
            return;
        }

        try {
            handleLoading(true);

            const url = `${apiUrl}/verify/dashboard-login?phoneNumber=${phoneNumber}&otp=${otp.join('')}`;
            const response = await axios.get(url, { withCredentials: true });
            handleLoading(false);
            window.location.reload(true)


        } catch (error) {
            handleError(error.response.data.message || error.response.data.error);
            handleLoading(false);
        }

    };

    return (
        <Box sx={{ textAlign: "center" }}>
            {error && <Typography sx={{ p: 2 }} color="error">{error}</Typography>}
            {loading && <Typography sx={{ p: 2 }}>Loading...</Typography>}
            {!isOtpVisible ? (
                <>
                    <TextField
                        label="Phone Number"
                        variant="outlined"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        sx={{ mb: 2, minWidth: 300 }}
                        maxLength={10}
                        required
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                        inputProps={{
                            inputMode: "numeric",
                            maxLength: 10, // This sets the maximum length to 10
                        }}
                    />
                    <Typography variant="subtitle2" color="text.secondary">Enter your registered phone number [Ex: 9876543210] to view dashboard.</Typography>

                    <br />
                    <Button variant="contained" onClick={handleSubmitPhone}>
                        Submit
                    </Button>
                </>
            ) : (
                <>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ p: 2 }}>Verification code has been sent to  ********{phoneNumber.slice(6, 10)}</Typography>

                    <Box sx={{ display: "flex", justifyContent: "center", gap: 1 }}>
                        {otp.map((digit, index) => (
                            <TextField
                                key={index}
                                id={`otp-input-${index}`} // Unique ID for each input
                                variant="outlined"
                                value={digit}
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                inputProps={{ maxLength: 1 }} // Limit to one digit
                                sx={{ width: '50px', textAlign: "center" }} // Set fixed width for the inputs
                            />
                        ))}
                    </Box>
                    <Button variant="contained" sx={{ mt: 2 }} onClick={handleVerifyOtp}>
                        Verify OTP
                    </Button>
                </>
            )}
        </Box>
    );
};

// Wrap DashboardLogin with the HOC
export default withAuth(DashboardLogin);
