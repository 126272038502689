import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Container,
    Tabs,
    Tab,
    useTheme,
    ListItem,
    ListItemText,
    Collapse,
    List,
    Paper,
    Divider,
    ListItemIcon,
    useMediaQuery
} from '@mui/material';
import { Link } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'; // Example icon, you can change it to any icon you prefer
import BookIcon from '@mui/icons-material/Book'; // Icon for My Bookings
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Cookies from 'js-cookie';

const LoginMenus = () => {


    const [isLogin, setLogin] = useState("")

    useEffect(() => {
        const loginCredentials = Cookies.get('loginToken');
        setLogin(loginCredentials)
    }, [])



    const [drawerOpen, setDrawerOpen] = useState(false);


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleSignout = () => {
        document.cookie = "loginToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    };


    const [tabIndex, setTabIndex] = useState(0);

    const themeSelector = useTheme();
    const isMobile = useMediaQuery(themeSelector.breakpoints.down('sm')); // Determine if the view is mobile

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    // Sample content for each tab
    const tabContent = [
        {
            label: 'Option',
            content: (
                <h3>Option</h3>
            )
        },
        {
            label: 'Download Receipt',
            content: (
                <></>
                // <DownloadReceiptContent />
            )
        },
    ];



    return (
        <Box
            mt={2}
            sx={{
                width: "100%",
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                position: "relative", // Ensure the parent container is relative to position the Paper absolutely
            }}
        >
            <Paper
                elevation={2}
                sx={{
                    // minWidth: { xs: 250 },
                    position: "absolute", // Make the dropdown pop above the content
                    top: "100%", // Position below the triggering button
                    right: 0, // Align to the right of the parent box
                    zIndex: 1000, // Ensure it's on top of other content
                }}
            >
                <ListItem
                    sx={{
                        backgroundColor: "#fff",
                        color: "#187a43",
                        borderBottom: "1px solid #eee",
                        cursor: "pointer",
                        "&:hover": {
                            backgroundColor: "#eeeeee",
                        },
                    }}
                    onClick={handleDropdownClick}
                >
                    {
                        isMobile ?
                            <>
                                <MenuIcon sx={{ color: "#187a43" }} /> {/* Adornment icon */}
                            </>
                            :
                            <>
                                <ListItemIcon>
                                    <MenuIcon sx={{ color: "#187a43" }} /> {/* Adornment icon */}
                                </ListItemIcon>
                                <ListItemText primary="Menu" />
                                {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                            </>
                    }

                </ListItem>
                <Divider />
                <Collapse in={dropdownOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {[
                            { text: "Download Receipt", icon: <BookIcon /> },
                        ].map((item, index) => (
                            <ListItem
                                key={index}
                                sx={{
                                    cursor: "pointer",
                                    color: "#187a43d6",
                                    borderBottom: "1px solid #eee",
                                    backgroundColor: "#fff",
                                    "&:hover": {
                                        backgroundColor: "#e0f2f1",
                                    },
                                }}
                                component={Link}
                                to={`/dashboard/${item.text.toLowerCase().replace(/ /g, "-")}`} // Dynamic routing
                                onClick={handleDrawerClose}
                            >
                                <ListItemIcon>
                                    {item.icon} {/* The icon for the list item */}
                                </ListItemIcon>
                                <ListItemText primary={item.text} />
                            </ListItem>
                        ))}

                        <Divider />

                        <ListItem
                            sx={{
                                color: "#187a43d6",
                                borderBottom: "1px solid #eee",
                                backgroundColor: "#eeeeee7a",
                                "&:hover": {
                                    backgroundColor: "#e0f2f1",
                                },
                            }}
                            component={Link}
                            to="/" // Assuming a sign-out route
                            onClick={handleSignout}
                        >
                            <ListItemIcon>
                                <ExitToAppIcon /> {/* Icon for sign-out */}
                            </ListItemIcon>
                            <ListItemText primary="Sign Out" />
                        </ListItem>
                    </List>
                </Collapse>
            </Paper>
        </Box>
    )
}

export default LoginMenus