import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import apiUrl from '../Api/api';

const LoginProtectedRoute = ({ children }) => {

    const navigate = useNavigate();
    const [user, setUser] = useState(null); // State to hold user information


    useEffect(() => {
        const url = `${apiUrl}/protected-route`;

        const fetchUser = async () => {
            try {
                const response = await axios.get(url, { withCredentials: true });
                setUser(response.data.user);
            } catch (error) {
                navigate("/dashboard");
            }
        };

        fetchUser();
    }, []);

    return (
        <>
            {React.cloneElement(children, { user })}
        </>
    )
}

export default LoginProtectedRoute;