import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import {
    Box,
    Typography,
    Container,
    Paper,
    Divider,
    IconButton,
} from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; // Importing a material icon for better visual

const TermsandConditions = () => {
    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 3 }}>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Terms and Conditions
                </Typography>

                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="body1" paragraph>
                        • Use of this site is provided by Goveda Foundation (henceforth referred to as Goveda) subject to the Terms and Conditions as given below.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Your use and access to the website constitute acceptance of these Terms and Conditions as at the date of your first use of the website.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • You agree to use the website only for lawful purposes, and in a manner which does not infringe the rights, or restrict, or inhibit the use and enjoyment of the website by any third party.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Goveda reserves the rights to change these Terms and Conditions at any time by posting changes online. Your continued use of the website after changes are posted constitutes your acceptance of the new Terms and Conditions as modified automatically. You should, therefore, review these Terms and Conditions regularly.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Goveda takes no responsibility for the content of external internet websites. Following links to any other websites or pages shall be at your own risk and Goveda shall not be responsible or liable, directly or indirectly, for any damages resulting from the use of such other websites.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • All intellectual property on the website and the material or information it contains including without limitation copyright, designs, database rights and trademarks (registered or unregistered) are and shall remain the property of Goveda or its third party licensors.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Commercial use or publication of all or any item displayed on the site without authorization from Goveda is strictly prohibited. Nothing contained in these Terms and Conditions shall be construed as conferring any license by Goveda to use any item displayed.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Materials in the website may be copied for personal use only on the condition that all copyright notices and source indications are also reproduced, no modifications are made and each relevant item is copied in its entirety. Some materials that have been outsourced have been published on the website with all the necessary permissions from the relevant copyright owners (who are not part of Goveda). All rights are reserved on these materials and permission to copy them must be requested from the individual copyright owners (as indicated within these materials).
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Any communication or material that you transmit to, or post on, any public area of the website including any data, questions, comments, suggestions, or the like, is and will be treated as non-confidential and non-proprietary information. Goveda reserves the right to remove any such communication or material from the website at its own discretion.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • If there is any conflict between these Terms and Conditions and rules and/or specific terms of use appearing on the Website relating to specific material then the latter shall prevail.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • These Terms and Conditions shall be governed and construed in accordance with the laws of India. Any disputes shall be subject to the non-exclusive jurisdiction of the Indian Courts.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • If these Terms and Conditions are not accepted in full, the use of the website must be terminated immediately.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Visitors acknowledge that visiting this site is an implicit acceptance of these 'Terms and Conditions' on their part.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        • Accepting 'Terms and Conditions' means an implicit acceptance of our Disclaimer and Privacy Policy.
                    </Typography>
                </Paper>

                <Divider sx={{ my: 4 }} /> {/* Divider for visual separation */}

                <Typography variant="h5" gutterBottom sx={{ mt: 4, fontWeight: 'bold' }}>
                    Donation Refund Policy
                </Typography>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="body1" paragraph>
                        Goveda has instituted a donation refund policy to ensure fair and transparent processing of requests for refund of donations as digital payments are becoming more frequent. Goveda expects that all donors will exercise due care and diligence while making donations. Goveda also recognizes that a donation may be made erroneously or donors may change their mind. Goveda will examine requests for refund of donations and endeavor to make the refund. Goveda may also seek proper explanation and reason from the user. Also, it will require further verification of the user and need documents of proof as well for the donation.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Goveda is not obliged to make refunds and may, in its discretion, decline any requests for refund of donations, particularly if a tax exemption certificate has been issued.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you would like your donation to be refunded, you must request Goveda in writing or by email for a refund at the following address:
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" paragraph>
                            Goveda Foundation
                            <br />
                            Shankar’s Nest,
                            <br />
                            9/7, Norton Main Road,
                            <br />
                            Mandavelipakkam, Chennai - 600028
                            <br />
                            Tamilnadu
                            <br />
                            e-mail: info@govedafoundation.org
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        Your refund request must contain the details such as Date of Donation, Amount of Donation, Mode of Donation i.e. Credit Card or Online Payment and should reach Goveda office within 7 (Seven) days from the date on which you made the donation i.e.:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The date on which you made the donation online, electronically or through other means, OR
                        <br />
                        The date on which you handed over the cheque/demand draft to Goveda or someone authorized by Goveda for this purpose, OR
                        <br />
                        The date on which you dispatched the cheque/demand draft to Goveda by other means.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The refund process may take time 7 to 30 working days depending on the availability of the information from third parties such as Payment Gateway, Banks, etc. through which transaction is done.
                    </Typography>
                </Paper>
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default TermsandConditions;
