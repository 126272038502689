import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import {
    Box,
    Typography,
    Container,
    Paper,
    Divider,
} from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';

const CancellationandRefund = () => {
    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 3 }}>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Cancellation and Refund Policy
                </Typography>

                <Typography variant="h6" gutterBottom sx={{ mt: 4, }}>
                    Donation Refund Policy
                </Typography>
                <Paper elevation={2} sx={{ p: 3, borderRadius: 2 }}>
                    <Typography variant="body1" paragraph>
                        Goveda has instituted a donation refund policy to ensure fair and transparent processing of requests for refund of donations as digital payments are becoming more frequent. Goveda expects that all donors will exercise due care and diligence while making donations. Goveda also recognizes that a donation may be made erroneously or donors may change their mind. Goveda will examine requests for refund of donations and endeavor to make the refund. Goveda may also seek proper explanation and reason from the user. Also, it will require further verification of the user and need documents of proof as well for the donation.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Goveda is not obliged to make refunds and may, in its discretion, decline any requests for refund of donations, particularly if a tax exemption certificate has been issued.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        If you would like your donation to be refunded, you must request Goveda in writing or by email for a refund at the following address:
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <Typography variant="body1" paragraph>
                            Goveda Foundation
                            <br />
                            Shankar’s Nest,
                            <br />
                            9/7, Norton Main Road,
                            <br />
                            Mandavelipakkam, Chennai - 600028
                            <br />
                            Tamilnadu
                            <br />
                            e-mail: info@govedafoundation.org
                        </Typography>
                    </Box>
                    <Typography variant="body1" paragraph>
                        Your refund request must contain the details such as Date of Donation, Amount of Donation, Mode of Donation i.e. Credit Card or Online Payment and should reach Goveda office within 7 (Seven) days from the date on which you made the donation i.e.:
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The date on which you made the donation online, electronically or through other means, OR
                        <br />
                        The date on which you handed over the cheque/demand draft to Goveda or someone authorized by Goveda for this purpose, OR
                        <br />
                        The date on which you dispatched the cheque/demand draft to Goveda by other means.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        The refund process may take time 7 to 30 working days depending on the availability of the information from third parties such as Payment Gateway, Banks, etc. through which transaction is done.
                    </Typography>
                </Paper>
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default CancellationandRefund;
