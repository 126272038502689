import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, Stack, MenuItem, InputLabel, FormControl, Select, Paper, Grid, InputAdornment } from '@mui/material';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import apiUrl from '../Api/api';

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PaymentIcon from '@mui/icons-material/Payment';
import { CalendarMonth } from '@mui/icons-material';

const AdminReceiptCreator = () => {


    const [formData, setFormData] = useState({
        name: '',
        address: '',
        amountinNumbers: '',
        amountinWords: '',
        paymentMode: '',
        date: '',
        supportOption: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };


    const receiptRef = React.useRef();


    const downloadReceipt = () => {
        html2canvas(receiptRef.current, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'mm',
                format: 'a4'
            });

            // A4 dimensions in mm
            const a4Width = 210; // mm
            const a4Height = 297; // mm
            const margin = 5; // 10mm margin

            const imgWidth = a4Width - margin * 2; // Adjust for left and right margins
            const imgHeight = (canvas.height * imgWidth) / canvas.width;

            // Add the image to the PDF
            let positionY = margin; // Starting position from top

            pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);

            // Check if the image height exceeds A4 page height
            let heightLeft = imgHeight - (a4Height - margin * 2);
            while (heightLeft >= 0) {
                pdf.addPage();
                positionY = heightLeft - imgHeight + margin; // Maintain top margin on new pages
                pdf.addImage(imgData, 'PNG', margin, positionY, imgWidth, imgHeight);
                heightLeft -= a4Height;
            }

            pdf.save('receipt.pdf'); // Save the PDF
        });
    };





    return (

        <Container maxWidth="lg">
            <AdminHeader />
            <Container maxWidth="sm">
                <Stack spacing={3} sx={{ mt: { xs: 5, md: 15 }, p: 2, bgcolor: '#f9f9f9', borderRadius: 2, boxShadow: 1 }}>
                    <Typography variant='h5' align='center' gutterBottom sx={{ fontWeight: 'bold', color: '#333' }}>
                        Download Receipt
                    </Typography>

                    <TextField
                        label="Name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ bgcolor: 'white' }}
                    />

                    <TextField
                        label="Address"
                        name="address"
                        value={formData.address}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <HomeIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ bgcolor: 'white' }}
                    />

                    <TextField
                        label="Amount in Numbers"
                        name="amountinNumbers"
                        value={formData.amountinNumbers}
                        onChange={handleChange}
                        fullWidth
                        type='number'
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MonetizationOnIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ bgcolor: 'white' }}
                    />

                    <TextField
                        label="Amount in Words"
                        name="amountinWords"
                        value={formData.amountinWords}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        sx={{ bgcolor: 'white' }}
                    />

                    <TextField
                        label="Payment Mode"
                        name="paymentMode"
                        value={formData.paymentMode}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <PaymentIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ bgcolor: 'white' }}
                    />

                    <TextField
                        name="date"
                        label="Date"
                        type="date"
                        value={formData.date}
                        onChange={handleChange}
                        fullWidth
                        variant="outlined"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CalendarMonth />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ bgcolor: 'white' }}
                    />

                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="support-option-label">Support Options</InputLabel>
                        <Select
                            labelId="support-option-label"
                            name="supportOption"
                            value={formData.supportOption}
                            onChange={handleChange}
                            sx={{ bgcolor: 'white' }}
                        >
                            <MenuItem value="Support Patashalas">Support Patashalas</MenuItem>
                            <MenuItem value="option1">Option 1</MenuItem>
                            <MenuItem value="option2">Option 2</MenuItem>
                            <MenuItem value="option3">Option 3</MenuItem>
                            <MenuItem value="option4">Option 4</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>

            </Container>
            <div>

                <Box sx={{ p: 3, maxWidth: 'lg', margin: '0 auto' }} >
                    <Paper
                        elevation={3}
                        sx={{
                            padding: 1.5,
                            border: "2px solid black",
                            m: 2,
                            width: '210mm', // Fixed width for A4
                            height: '297mm', // Fixed height for A4
                            boxSizing: 'border-box', // Include padding and border in the element's total width and height
                        }}
                        ref={receiptRef}
                    >
                        <Grid container alignItems="center" sx={{ borderBottom: "1px solid grey", p: 1 }}>
                            <Grid item xs={6}>
                                <Box>
                                    <img src={require("../images/goveda-logo.png")} alt="GoVeda Logo" height={50} />
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box textAlign="right">
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                        GoVeda Foundation
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                        Functional Office: Door No. 4D1, Fourth Floor, Tower 1, <br />
                                        Shakthi Towers, #766, Anna Salai, Chennai - 600 002. <br />
                                        Phone: 044 48607558 / 98416 26380
                                    </Typography>
                                    <Typography variant="subtitle2" sx={{ fontSize: '0.6rem', color: "black" }}>
                                        Email: info@govedafoundation.org <br />
                                        Website: www.govedafoundation.org <br />
                                        Registration No: 397/2017/BK4
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ my: 2 }}>
                            <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                                Thank You Letter Cum Receipt
                            </Typography>

                            <Grid container alignItems="center" sx={{ mt: 2, }}>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" align="left" >
                                        Dear Shri <Box component="span" sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{formData.name}</Box>,
                                    </Typography>
                                </Grid>

                                <Grid item xs={6} sx={{ textAlign: 'right', fontSize: '0.9rem' }}>
                                    <Typography variant="subtitle1">
                                        Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.date}</Box>
                                    </Typography>
                                </Grid>
                            </Grid>

                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                            Thank you for your valuable contribution towards the cause of <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.supportOption}</Box>. We at GoVeda Foundation are
                            committed to serving humanity by preserving the age-old knowledge bestowed upon us by our Rishis and
                            forefathers.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We welcome you into this sathsanga of like-minded people who share their resources for activities that
                            will help leave a legacy for our future generations.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We also invite you to be volunteers for our various activities as and when required at your convenience.
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1, fontSize: '0.9rem' }}>
                            We look forward to your continued patronage.
                        </Typography>

                        <Box sx={{ mt: 3, mb: 3, borderTop: "1px solid grey", p: 3 }} >
                            <Typography variant="h5" align='center' sx={{ fontWeight: "bold", }}>Receipt</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={6} >
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }}>Receipt No.: 0002</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '0.9rem' }} align="right">Date: <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.date}</Box></Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                            Received with thanks from Shri <Box component="span" sx={{ fontWeight: 'bold', }}> {formData.name}</Box>, address: <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.address}</Box>, the sum of Rupees <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.amountinWords}</Box> only towards <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.supportOption}</Box> by <Box component="span" sx={{ fontWeight: 'bold', }}>{formData.paymentMode}</Box>.
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item xs={6}>
                                <Typography variant="subtitle1" align="left" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                    Rs.<Box component="span" sx={{ fontWeight: 'bold', }}>{formData.amountinNumbers}.00</Box>
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: 'right' }}>
                                <Box sx={{ mb: 1 }}>
                                    <img src={require("../images/goveda-logo.png")} alt="Signature" height={50} />
                                </Box>

                                <Typography variant="subtitle1" sx={{ mt: 2, fontSize: '0.9rem' }}>
                                    For GoVeda Foundation
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>

                {/* <Box sx={{ p: { xs: 0, md: 2 }, maxWidth: 'lg', margin: '0 auto' }}>
                    <Paper elevation={3} sx={{ padding: 2, border: "2px solid black", m: 2 }}>
                        <Grid container alignItems="center" sx={{ borderBottom: "1px solid grey", p: 2 }}>
                            <Grid item xs={12} sm={6}>
                                <Box>
                                    <img src={require("../images/goveda-logo.png")} alt="GoVeda Logo" height={80} />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box textAlign="right">
                                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                                        GoVeda Foundation
                                    </Typography>
                                    <Typography variant="body2">
                                        Functional Office: Door No. 4D1, Fourth Floor, Tower 1,<br />
                                        Shakthi Towers, #766, Anna Salai, Chennai - 600 002.<br />
                                        Phone: 044 48607558 / 98416 26380
                                    </Typography>
                                    <Typography variant="body2">
                                        Email: info@govedafoundation.org<br />
                                        Website: www.govedafoundation.org<br />
                                        Registration No: 397/2017/BK4
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box sx={{ my: 2 }}>
                            <Typography variant="h5" align="center" sx={{ fontWeight: "bold" }}>
                                Thank You Letter Cum Receipt
                            </Typography>

                            <Grid container alignItems="center" sx={{ mt: 2 }}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6" align="left">
                                        Dear Shri <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.name}</Box>,
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                                    <Typography variant="h6">
                                        Date: <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.date}</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Typography variant="body1" sx={{ mt: 2 }}>
                            Thank you for your valuable contribution towards the cause of <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.supportOption}</Box>. We at GoVeda Foundation are committed to serving humanity by preserving the age-old knowledge bestowed upon us by our Rishis and forefathers.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            We welcome you into this sathsanga of like-minded people who share their resources for activities that will help leave a legacy for our future generations.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            We also invite you to be volunteers for our various activities as and when required at your convenience.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            We look forward to your continued patronage.
                        </Typography>

                        <Box sx={{ mt: 3, mb: 3, borderTop: "1px solid grey", p: 2 }}>
                            <Typography variant="h5" align='center' sx={{ fontWeight: "bold" }}>Receipt</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6">Receipt No.: 0002</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant="h6" align="right">
                                        Date: <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.date}</Box>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>

                        <Typography variant="body1" sx={{ mt: 2 }}>
                            Received with thanks from Shri <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.name}</Box>, address: <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.address}</Box>, the sum of Rupees <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.amount}</Box> only towards <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.supportOption}</Box> by <Box component="span" sx={{ fontWeight: 'bold' }}>{formData.paymentMode}</Box>.
                        </Typography>

                        <Grid container alignItems="center">
                            <Grid item xs={12} sm={6}>
                                <Typography variant="h5" align="left" sx={{ mt: 2 }}>
                                    Rs.<Box component="span" sx={{ fontWeight: 'bold' }}>{formData.amount}.00</Box>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ textAlign: 'right' }}>
                                <Box sx={{ mb: 1 }}>
                                    <img src={require("../images/goveda-logo.png")} alt="Signature" height={50} />
                                </Box>

                                <Typography variant="h6" sx={{ mt: 2 }}>
                                    For GoVeda Foundation
                                </Typography>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box> */}

                <Button variant="contained" color="primary" onClick={downloadReceipt} sx={{ textAlign: "center", p: 2, ml: { xs: 10, md: 40 } }}>
                    Download Receipt
                </Button>

            </div>
        </Container>
    );
};

export default AdminReceiptCreator;


