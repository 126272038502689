import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import { Grid, Box, Typography, Button, Card, CardMedia, Container, CardContent, CardActions, Divider } from '@mui/material';

const SupportPatashalas = () => {

    const otherCauses = [
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Agnihothra Vruddhi',
            description: 'Agni is the Lord of fire who devours and purifies. He helps us in our...',
            link: 'agnihotra-vruddhi',
        },
        {
            image: 'https://picsum.photos/id/277/200/300',
            title: 'Annadanam',
            description: 'Annadanam plays a vital role in any dharmic activity...',
            link: 'annadanam',
        },
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Village Parayanam',
            description: 'Agni is the Lord of fire who devours and purifies...',
            link: 'village-temple-parayanam',
        },
    ];

    return (
        <div>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />

                <Container sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Grid container spacing={4}>
                        {/* Main Content */}
                        <Grid item xs={12} md={8}>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    The Patashala
                                </Typography>
                                <Typography paragraph>
                                    Goveda foundation is presently running a patashala namely <strong>Shri Kamakoti Veda Shastra Vidyalaya</strong> at a spacious premises in Kamakoti Nagar, Pallikaranai, Chennai. The name of the patashala was bestowed by <strong>Bala Periava</strong> (HH Sri Sankara Vijayendra Saraswathi Swamigal) himself. Presently there are 15 vidyarthis studying at different stages and sections of Krishna yajur veda. We look forward to enrolling more children after proper screening.
                                </Typography>
                                <Typography variant="h6" gutterBottom >
                                    Patashala Address:
                                </Typography>
                                <Typography component="address" paragraph>
                                    Shri Kamakoti Veda Shastra Vidyalaya<br />
                                    Plot No.1, Nagammal Avenue, Narayanapuram (Off Kamakoti Nagar 2nd Main Rd.),<br />
                                    Pallikaranai, Chennai - 600100.
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    The Vidyarthi
                                </Typography>
                                <Typography paragraph>
                                    The vidyarthis who have completed their upanayanam are enrolled after proper evaluation of comprehension, enunciation, parent’s interest, etc. It is recommended that the children start learning the vedas as early as 9 years when their comprehension skills are at the peak. This would also help them complete the vedic lessons faster and study the various shastras like Mimamsa, Vyakarana, Vedanta, Jyothisha, etc., which are equally important and help them lead a scholarly life that would benefit the society at large.
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    The Lessons
                                </Typography>
                                <Typography paragraph>
                                    Under the guidance of Ananda Ganapadigal of Kanchipuram, the children are taught Krishna Yajur Veda by a resident adhyapakar, Shri Saikumar Sharma. Special emphasis is being given in imparting knowledge in Sanskrit, English, and Arithmetic with the help of individuals well-versed in teaching and institutions like Samskrita Bharati.
                                </Typography>
                                <Typography paragraph>
                                    The vidyarthis will be trained through adequate exposure to Agnihotra, shrowtha karmas, and various prayogams like performing vedic rituals and poojas. They will also be given exposure to natural farming practices during their free time, thus they could be of use to the community that they will be part of in future.
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    The Incentive
                                </Typography>
                                <Typography paragraph>
                                    Today, parents are spending several lakhs over a period of 15 years (KG to 12th) in an education system that carries no guarantee for the future of their children on completion of such studies. At our patashala, apart from free education, boarding, food, and clothing, a vidyarthi who completes his Ganantham or Kramantham within the stipulated time, Goveda Foundation would reward him Rs. 50,000 or Rs. 25,000 respectively.
                                </Typography>
                                <Typography paragraph>
                                    Further, such a student is also assured of handsome monthly income of at least Rs. 30,000 p.m. by attending various rituals and veda parayanams held all over India.
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    The Future
                                </Typography>
                                <Typography paragraph>
                                    Goveda Foundation yearns to make this patashala a premium institution for learning Vedas and Shastras. We would also want to be a catalyst in encouraging Vedic studies through other patashalas and Vedic scholars.
                                </Typography>
                                <Typography paragraph>
                                    We would like to conduct programs like Vedic contest, seminars, Kalvaithavaram, etc., for students and scholars and reward the achievers substantially.
                                </Typography>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Our Appeal
                                </Typography>
                                <Typography paragraph>
                                    Dharmo Rakhshati Rashitaha: If we protect our dharmic values, Dharma will protect us and our future generations.
                                </Typography>
                                <Typography paragraph>
                                    We appeal to all like-minded individuals and institutions to accord your support for this noble cause. Some of the ways you may contribute are listed below:
                                </Typography>

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Typography>Samaradhanai (Lunch with Vada Payasam)</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 4,000/-</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Vidyarthi Vastrams</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 500/- per set</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Sponsoring a Vidyarthi’s expense for a year</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 50,000/-</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Provisions and Vegetables</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>In Kind</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Vidyarthi Sambhavana (Scholarship) on Completion of Kramantham</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 25,000/-</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Ghanantham</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 50,000/-</Typography>
                                    </Grid>

                                    <Grid item xs={6}>
                                        <Typography>Contribute to the Corpus Fund</Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography>Rs. 10,000/- or its multiples</Typography>
                                    </Grid>
                                </Grid>
                            </Box>

                            <Divider />

                            <Box sx={{ my: 3, textAlign: 'center' }}>
                                <Typography paragraph>
                                    Irrespective of whatever amount is mentioned above, please feel free to donate any amount of your choice. We value every rupee.
                                </Typography>
                                <Typography variant="h4" gutterBottom>
                                    Together We Will Make It Big!
                                </Typography>
                                <Button variant="contained" color="primary" href="/donate">
                                    Donate Now
                                </Button>
                            </Box>
                        </Grid>
                        {/* Sidebar: Other Causes */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="h6" sx={{ mb: 2 }}>
                                Other Causes
                            </Typography>
                            {otherCauses.map((cause, index) => (
                                <Card key={index} sx={{ mb: 3 }}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={cause.image}
                                        alt={cause.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">{cause.title}</Typography>
                                        <Typography variant="body2" color="text.secondary">{cause.description}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" variant='contained' color="primary" href={cause.link}>
                                            Learn More
                                        </Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </Container>

                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default SupportPatashalas;
