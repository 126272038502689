import React, { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import axios from 'axios'; // Import axios
import { Box, Typography, TextField, Button, Container, Paper, Stack, Snackbar, Alert, InputAdornment } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import apiUrl from '../Api/api';


import PersonIcon from '@mui/icons-material/Person';
import MessageIcon from '@mui/icons-material/Message';

const Contact = () => {
    // State to manage form input values
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');


    // Handle form input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // Send form data to the backend
            const url = `${apiUrl}/contactpage`;
            const response = await axios.post(url, formData);
            if (response.status === 200) {
                setSnackbarMessage('Form submitted successfully!');
                setSnackbarSeverity('success');
                setFormData({
                    name: '',
                    email: '',
                    phone: '',
                    message: ''
                });
            }
        } catch (error) {
            setSnackbarMessage('Form submission failed. Please try again later.');
            setSnackbarSeverity('error');
        }
        setOpenSnackbar(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnackbar(false);
    };


    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container sx={{ mt: 2 }}>
                <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEu6-A0N8KC3QPP_oBiuqa-JVic3tU90M_vnP6YvzoFNAGlfZOlLhS_hmr3cfQzmYw6A&usqp=CAU' width="100%" height={150} />
            </Container>
            <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
                <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ m: 3 }}>
                    Contact Us
                </Typography>
                <Stack spacing={4} direction={{ xs: "column", md: "row" }} justifyContent="space-between">
                    <Paper elevation={3} sx={{ padding: 3, flex: 1 }}>
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', p: { xs: 2, md: 2 }, color: "#318065" }}>
                            Send Your Message
                        </Typography>

                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2} sx={{ borderRadius: 2, }}>
                                <TextField
                                    fullWidth
                                    label="Name"
                                    name="name"
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PersonIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Email"
                                    name="email"
                                    type="email"
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Mobile Number"
                                    name="phone"
                                    type="tel"
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    label="Message"
                                    name="message"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    margin="normal"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    required
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MessageIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                >
                                    Submit
                                </Button>
                            </Stack>
                        </form>

                    </Paper>

                    <Box
                        sx={{
                            textAlign: 'center',
                            backgroundColor: '#ffffff',
                            padding: 2,
                            borderRadius: 2,
                            boxShadow: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            justifyContent: 'space-evenly'
                        }}
                    >
                        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', p: { xs: 2, md: 0 }, color: "#318065" }}>
                            GoVeda Foundation
                        </Typography>
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                            <LocationOnIcon sx={{ mr: 1 }} />
                            <Typography variant="body1" textAlign="left">
                                Plot No.1, Nagammal Avenue, Narayanapuram,<br />
                                Pallikaranai, Chennai - 600 100.<br />
                                Tamil Nadu, India.
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mb: 1 }}>
                            <PhoneIcon sx={{ mr: 1 }} />
                            <Typography variant="body1">Phone:  </Typography> +91 7825807830
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center">
                            <EmailIcon sx={{ mr: 1 }} />
                            <Typography variant="body1">E-mail:  </Typography> info@govedafoundation.org
                        </Box>
                    </Box>
                </Stack>
            </Container>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Footer />
        </ThemeProvider>
    );
};

export default Contact;
