import React from 'react';
import ScrollToTop from "react-scroll-to-top";
import Header from '../components/Header';
import theme from '../components/Theme';
import { ThemeProvider } from '@mui/material';
import HomeComponent from '../components/HomeComponent';
import Footer from '../components/Footer';

const LandingPage = () => {
    return (
        <div className='overall-container'>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083 v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584 c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005 C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />



            <ThemeProvider theme={theme}>

                <Header />
                <HomeComponent />
                <Footer />

            </ThemeProvider>

        </div>
    );
};

export default LandingPage;