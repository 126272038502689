import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import {
    Box,
    Typography,
    Container,
    Paper,
    Divider,
    IconButton,
} from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'; // Importing a material icon for better visual

const ShippingandDelivery = () => {
    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 3 }}>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Shipping and Delivery
                </Typography>

                <Typography variant="body1" textAlign="center" sx={{ p: 5 }} paragraph>
                    As of now, there is no shipping or delivery.
                </Typography>



            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default ShippingandDelivery;
