import axios from 'axios';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import apiUrl from '../Api/api';

const AdminProtectedroute = ({ children }) => {

    const navigate = useNavigate();

    useEffect(() => {

        const url = `${apiUrl}/auth/admin-dashboard`;

        axios.get(url, { withCredentials: true })
            .then((response) => {
            })
            .catch((error) => {
                navigate("/admin-login")
            })

    }, [])


    return (
        <>{children}</>
    )
}

export default AdminProtectedroute;