import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container } from '@mui/material';
import axios from 'axios';
import AdminHeader from './AdminHeader';
import apiUrl from '../Api/api';

const PaymentLinkForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    phone: ''
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setErrorMessage('');

    // Replace with your backend URL where the Razorpay API request is handled
    const url = `${apiUrl}/auth/create-payment-link`;

    try {
      const response = await axios.post(url, formData);
      if (response.data.success) {
        setSuccessMessage(`Payment link sent successfully!`);
      } else {
        setErrorMessage('Failed to send payment link.');
      }
    } catch (error) {
      setErrorMessage('An error occurred while sending the payment link.');
      console.error(error);
    }

    setLoading(false);
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   setSuccessMessage('');
  //   setErrorMessage('');

  //   const backendUrl = 'http://localhost:4000/create-payment-link';

  //   try {
  //     const response = await axios.post(backendUrl, formData);
  //     if (response.data.success) {
  //       window.location.href = response.data.url;  // Redirect to payment page
  //     } else {
  //       setErrorMessage('Failed to redirect to payment page.');
  //     }
  //   } catch (error) {
  //     setErrorMessage('Error redirecting to payment page.');
  //     console.error(error);
  //   }

  //   setLoading(false);
  // };


  return (
    <Container maxWidth="sm">
      <AdminHeader />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: 15,
          padding: 4,
          border: '1px solid #ccc',
          borderRadius: '8px',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Send Payment Link
        </Typography>
        <form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <TextField
            fullWidth
            margin="normal"
            label="Email"
            name="email"
            variant="outlined"
            value={formData.email}
            onChange={handleInputChange}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Phone"
            name="phone"
            variant="outlined"
            value={formData.phone}
            onChange={handleInputChange}
            required
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            type="submit"
            sx={{ mt: 3 }}
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send Payment Link'}
          </Button>
        </form>

        {successMessage && (
          <Typography color="green" sx={{ mt: 2 }}>
            {successMessage}
          </Typography>
        )}
        {errorMessage && (
          <Typography color="red" sx={{ mt: 2 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </Container>
  );
};

export default PaymentLinkForm;
