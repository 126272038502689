import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import { Container, Grid, Typography, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Link, Button, Box, CardMedia, CardActions } from '@mui/material';

const VillageParayanam = () => {

    const otherCauses = [
        {
            image: 'https://picsum.photos/id/257/200/300',
            title: 'Support Patashalas',
            description: 'The Patashala specializes in teaching the ancient Indian traditions...',
            link: 'support-patashalas',
        },
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Agnihothra Vruddhi',
            description: 'Agni is the Lord of fire who devours and purifies. He helps us in our...',
            link: 'agnihotra-vruddhi',
        },
        {
            image: 'https://picsum.photos/id/277/200/300',
            title: 'Annadanam',
            description: 'Annadanam plays a vital role in any dharmic activity...',
            link: 'annadanam',
        },
    ];

    const eventData = [
        {
            id: 1,
            venue: "Sri Jalanadheeswarar Temple, Thakkolam Post, Arakonam Taluk, Tamilnadu",
            date: "10 Dec 2020 to 11 Dec 2020",
            invitationLink: "https://www.govedafoundation.org/invitations/Thakkolam_Invite.pdf",
        },
        {
            id: 2,
            venue: "Sri Manikandeswarar Temple, Thirumalpur Post, Arakonam Taluk, Tamilnadu.",
            date: "02 Jan 2021 to 03 Jan 2021",
            invitationLink: "https://www.govedafoundation.org/invitations/2_Tirumalpur_Invite.pdf",
        },
        {
            id: 3,
            venue: "Sri Vaanasundareswarar Temple, Manampathy Post, Uthiramerur Taluk, Tamilnadu.",
            date: "06 Feb 2021 to 07 Feb 2021",
            invitationLink: "https://www.govedafoundation.org/invitations/Manampathy-Invitation.pdf",
        },
    ];


    return (
        <div>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />

                <Container sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Grid container spacing={4}>
                        {/* Main Content */}
                        <Grid item xs={12} md={8}>
                            <Box
                                sx={{ mb: 4 }}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="400"
                                        image='https://img.freepik.com/free-photo/majestic-mountain-peak-tranquil-winter-landscape-generated-by-ai_188544-15662.jpg'
                                        alt="Annadanam"
                                        sx={{ objectFit: 'cover' }}
                                    />
                                </Card>
                            </Box>

                            {/* Text Section */}
                            <Container maxWidth="lg" sx={{ marginTop: '2rem' }}>
                                <Grid container spacing={3}>
                                    {/* Introduction Section */}
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Village Temple Parayanam</Typography>
                                                <Typography variant="body1" paragraph>
                                                    Nowadays, in village temples, Vedhaghoshams are not heard so frequently. Kanchi Mahaperiyava emphasized the importance of Village temple parayanam on several occasions.
                                                </Typography>
                                                <Typography variant="body1" paragraph>
                                                    Hence, GOVEDA going towards the path of MahaPeriyava, wants to cultivate this and encourage more Vaideehas to perform Veda Parayanam in daily routine providing the basic amenities with full care.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    {/* Invitation Section */}
                                    <Grid item xs={12}>
                                        <Card>
                                            <CardContent>
                                                <Typography variant="h5" gutterBottom>Namaste,</Typography>
                                                <Typography variant="body1" paragraph>
                                                    With the benign blessings of Kanchi Periava, we are happy to announce the third Village Temples Veda Parayanam. This program will be conducted periodically at different village temples in order to enhance the sannithyam of the deity and benefit everyone.
                                                </Typography>
                                                <Typography variant="body1">
                                                    We invite all Aasthikas to be part of this endeavour to reclaim the glory of our ancient civilization.
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>

                                    {/* Table Section */}
                                    <Grid item xs={12}>
                                        <TableContainer component={Paper}>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>S.NO</TableCell>
                                                        <TableCell>VENUE</TableCell>
                                                        <TableCell>DATE</TableCell>
                                                        <TableCell>INVITATION</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {eventData.map((event) => (
                                                        <TableRow key={event.id}>
                                                            <TableCell>{event.id}</TableCell>
                                                            <TableCell>{event.venue}</TableCell>
                                                            <TableCell>{event.date}</TableCell>
                                                            <TableCell>
                                                                <Button variant="contained" color="primary" href={event.invitationLink} target="_blank" rel="noopener noreferrer">
                                                                    View Invitation
                                                                </Button>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Container>
                        </Grid>

                        {/* Sidebar */}
                        <Grid item xs={12} md={4}>
                            <Typography variant="h5" gutterBottom>
                                Other Causes
                            </Typography>
                            {otherCauses.map((cause, index) => (
                                <Card sx={{ mb: 4 }} key={index}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={cause.image}
                                        alt={cause.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">
                                            <a href={cause.link} style={{ textDecoration: 'none', color: '#000' }}>
                                                {cause.title}
                                            </a>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {cause.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant='contained' href={cause.link}>Read More</Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default VillageParayanam;
