import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// Import Material-UI components
import { Grid, Card, CardContent, Typography, Button, CardActions, CardMedia, Box, Container, Stack, useTheme, useMediaQuery } from '@mui/material';
import theme from './Theme';



const content = [
    {
        title: "Support Patashalas",
        description: "The Patashala specializes in teaching the ancient Indian traditions...",
        image: "https://picsum.photos/200/300?random=1",
        link: "#"
    },
    {
        title: "AgnihothraVruddhi",
        description: "Agni is the Lord of fire who devours and purifies. He helps us in our...",
        image: "https://picsum.photos/200/300?random=2",
        link: "#"
    },
    {
        title: "Go Samrakshana",
        description: "GO Samrakshana” - it means “the protection of Cows”...",
        image: "https://picsum.photos/200/300?random=3",
        link: "#"
    },
    {
        title: "Zero Budget Natural Farming",
        description: "Rushi – meaning agriculture which has been given a great...",
        image: "https://picsum.photos/200/300?random=4",
        link: "#"
    },
    {
        title: "Vaiddeehal Matrimony",
        description: "Vaideehas are the eyes of our Veda. Without Vaideehas...",
        image: "https://picsum.photos/200/300?random=5",
        link: "#"
    },
    {
        title: "Village Temple Parayanam",
        description: "Nowadays, in village temples, Vedhaghoshams are...",
        image: "https://picsum.photos/200/300?random=6",
        link: "#"
    },
    {
        title: "Annadanam",
        description: "Annadbhavanthibhootani” – These are the words of Sri Krishna in...",
        image: "https://picsum.photos/200/300?random=7",
        link: "#"
    },
];



const HomeComponent = () => {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))

    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    return (
        <>
            <div className='hero-slider'>
                <Swiper
                    spaceBetween={30}
                    centeredSlides={true}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    onAutoplayTimeLeft={onAutoplayTimeLeft}
                    className="mySwiper"
                >
                    {/* Example Slide 1 */}
                    <SwiperSlide>
                        <Box sx={{ width: "100%", position: 'relative' }}>
                            <img
                                src=
                                "https://img.freepik.com/free-photo/majestic-mountain-peak-tranquil-winter-landscape-generated-by-ai_188544-15662.jpg"
                                alt="Hero Background"
                                width="100%"
                                height={400}
                                sx={{ objectFit: 'cover' }} // Full height and background behavior
                            />
                        </Box>
                        <Container
                            sx={{
                                textAlign: 'center',
                                color: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                position: 'absolute', // Make the container overlay the image
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 2 // Ensure content stays on top of the image
                            }}
                        >
                            <Typography variant="h4" component="div">
                                Join Us Today
                            </Typography>
                            <Typography variant="body1" sx={{ my: 2 }}>
                                Sign up now and start your journey with us.
                            </Typography>
                            <Box sx={{ mt: 4 }}>
                                <Button variant="contained" color="primary" sx={{ mx: 1, color: "white" }}>
                                    Donate Now
                                </Button>
                                <Button variant="contained" color="secondary" sx={{ mx: 1, color: "white" }}>
                                    Contact Us
                                </Button>
                            </Box>
                        </Container>
                    </SwiperSlide>


                    {/* Example Slide 2 */}
                    <SwiperSlide>
                        <Box sx={{ width: "100%", position: 'relative' }}>
                            <img
                                src={
                                    isMobile ?
                                        "https://img.freepik.com/free-photo/painting-three-feathered-feathers-with-word-o-bottom_1340-32314.jpg?t=st=1727682373~exp=1727685973~hmac=7b5c853a7e647d1c6ff0e48ea23bf08f7dd6b4584e22a851cf964bba1102ccc5&w=740"
                                        :
                                        "https://img.freepik.com/free-vector/gradient-abstract-fluid-technology-linkedin-banner_23-2149032011.jpg?t=st=1727682506~exp=1727686106~hmac=b22f83368e81815c2b2cf8dc38b984fd3a1a8cd5ef4d8f60e846495e565be9b6&w=1060"}
                                alt="Hero Background"
                                width="100%"
                                style={{ objectFit: isMobile ? "cover" : "contain", height: isMobile ? "100%" : "100%", zIndex: 1, }} // Full height and background behavior
                            />
                        </Box>
                        <Container
                            sx={{
                                textAlign: 'center',
                                color: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                height: '100%',
                                position: 'absolute', // Make the container overlay the image
                                top: 0,
                                left: 0,
                                right: 0,
                                zIndex: 2 // Ensure content stays on top of the image
                            }}
                        >
                            <Typography variant="h4" component="div">
                                Join Us Today
                            </Typography>
                            <Typography variant="body1" sx={{ my: 2 }}>
                                Sign up now and start your journey with us.
                            </Typography>
                            <Box sx={{ mt: 4 }}>
                                <Button variant="contained" color="primary" sx={{ mx: 1, color: "white" }}>
                                    Donate Now
                                </Button>
                                <Button variant="contained" color="secondary" sx={{ mx: 1, color: "white" }}>
                                    Contact Us
                                </Button>
                            </Box>
                        </Container>
                    </SwiperSlide>


                    {/* Additional slides can be added similarly */}

                    <div className="autoplay-progress" slot="container-end">
                        <svg viewBox="0 0 48 48" ref={progressCircle}>
                            <circle cx="24" cy="24" r="20"></circle>
                        </svg>
                        <span ref={progressContent}></span>
                    </div>
                </Swiper>

            </div >
            <Box className="home-body" sx={{ p: { xs: 2, md: 8 } }}>
                <Stack>
                    <Typography variant='h4' gutterBottom sx={{ fontWeight: "bold", textAlign: "center", my: 2 }}>Welcome to GOVEDA !!!
                    </Typography>
                    <Typography variant='body5' sx={{ p: 2, textAlign: "center", }} color='textSecondary'>GOVEDA foundation is a Religious Charitable Trust founded to carry on the legacy of our ancestral dharma of protecting “GO” and “VEDA “.
                    </Typography>
                    <Typography variant='body5' sx={{ p: 2, textAlign: "center" }} color='textSecondary'>We welcome you to participate physically or monetarily and support the various causes/activities mentioned below and help us serve and protect our Dharma:
                    </Typography>
                </Stack>
                <Grid container spacing={4} sx={{ p: 2 }}>
                    {content.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    cursor: "pointer",
                                    maxWidth: 345,
                                    // transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        // transform: 'scale(1.05)',
                                        boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
                                    }
                                }}
                            >
                                <CardMedia
                                    component="img"
                                    height="200"
                                    image={item.image}
                                    alt={item.title}
                                />
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="div">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        size="medium"
                                        href={item.link}
                                        sx={{
                                            border: "1px solid grey",
                                            textTransform: "none",
                                            backgroundColor: 'primary.main',
                                            color: "white",
                                            '&:hover': {
                                                backgroundColor: 'primary.dark',
                                            }
                                        }}
                                    >
                                        Read More
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>

            </Box>
        </>
    );
};

export default HomeComponent;
