import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';
import { Grid, Box, Typography, Button, Card, CardMedia, CardContent, Container, CardActions } from '@mui/material';

const AgnihotraVruddhi = () => {

    const otherCauses = [
        {
            image: 'https://picsum.photos/id/257/200/300',
            title: 'Support Patashalas',
            description: 'The Patashala specializes in teaching the ancient Indian traditions...',
            link: 'support-patashalas',
        },
        {
            image: 'https://picsum.photos/id/277/200/300',
            title: 'Annadanam',
            description: 'Annadanam plays a vital role in any dharmic activity...',
            link: 'annadanam',
        },
        {
            image: 'https://picsum.photos/id/267/200/300',
            title: 'Village Parayanam',
            description: 'Agni is the Lord of fire who devours and purifies...',
            link: 'village-temple-parayanam',
        },
    ];

    return (
        <div>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />

                <Container sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                    <Grid container spacing={4}>
                        {/* Main Content */}
                        <Grid item xs={12} md={8}>
                            {/* Image Section */}
                            <Box sx={{ mb: 4 }}>
                                <Card>
                                    <CardMedia
                                        component="img"
                                        height="400"
                                        image='https://www.shutterstock.com/image-photo/calm-weather-on-sea-ocean-600nw-2212935531.jpg'
                                        alt="Agnihotra Vruddhi"
                                        sx={{ objectFit: 'cover' }}
                                    />
                                </Card>
                            </Box>

                            {/* Text Section */}
                            <Box sx={{ p: 1 }}>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Agnihotra Vruddhi:
                                </Typography>
                                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                                    AGNI – the divine will: Agni is the will of seers; Supreme in spiritual audition; the very truth. (Rig .Veda 1.1.5)
                                </Typography>
                                <Typography paragraph>
                                    Agni is the Lord of fire who devours and purifies. He helps us in our life in many aspects. Without him how can we live? He is the thunderbolt in the atmosphere and the sun in the heavens. He is the Grihapathi – the genius of Yajamana (the performer of Yagna). The Yajamana respects and utilizes the lord in the correct perspective.
                                </Typography>
                                <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                                    Agnihotram:
                                </Typography>
                                <Typography paragraph>
                                    The benefits of Agnihotra are manifold. Many of you would have heard of the story of how one village that housed an Agnihotri remained unaffected during the Bhopal Gas Tragedy. Google is abound with information and research papers on how Agnihotra purifies the atmosphere and reenergises the ecology etc.
                                </Typography>
                                <Typography paragraph>
                                    More than anything, it is believed that the decline in Agnihotra is directly proportional to an increase in mental disability or birth-related illness in children. From a society where such disabilities were unheard of, we have now moved to opening several schools and organizations for such children.
                                </Typography>
                                <Typography paragraph>
                                    Vedic scholars opine that the offerings given in the Agnihotra, along with appropriate Mantras, feed the child in the womb of mothers across the globe, irrespective of caste, creed, race, or even species. As the performance of Agnihotra has declined, the health of unborn children has been subject to various attacks.
                                </Typography>
                                <Typography paragraph>
                                    Goveda Foundation has taken up this noble task of Agnihotra Vruddhi. We aim to propagate at least 108 Agnihotris across India in the near future. We will identify vaideehas (vedic scholars) of the present time or groom young children from various patashalas for this purpose. We will support them with:
                                </Typography>
                                <Typography paragraph>
                                    a. About 40 to 50 cents of agricultural land in a village of their choice. <br />
                                    b. Setup of Zero Budget Natural Farm (ZBNF) that will help them sustain monetarily. <br />
                                    c. Provide them with a Desi cow, integral to ZBNF. <br />
                                    d. Assist in building a house within the farm.
                                </Typography>
                                <Typography paragraph>
                                    We look forward to support from like-minded people for this mammoth project. We appeal to you to donate for the better future of our children.
                                </Typography>

                                <Box sx={{ marginY: 3 }}>
                                    <Button variant="contained" color="primary" href="/donate">
                                        Donate Now
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>

                        {/* Sidebar */}
                        <Grid item xs={12} md={4} >
                            <Typography variant="h5" gutterBottom>
                                Other Causes
                            </Typography>
                            {otherCauses.map((cause, index) => (
                                <Card sx={{ mb: 4 }} key={index}>
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={cause.image}
                                        alt={cause.title}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">
                                            <a href={cause.link} style={{ textDecoration: 'none', color: '#000' }}>
                                                {cause.title}
                                            </a>
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            {cause.description}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant='contained' href={cause.link} >Read More</Button>
                                    </CardActions>
                                </Card>
                            ))}
                        </Grid>
                    </Grid>
                </Container>
                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default AgnihotraVruddhi;
