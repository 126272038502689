import React, { useState } from 'react';
import { TextField, Button, Box, Typography, Container, ThemeProvider } from '@mui/material';
import theme from '../components/Theme';
import apiUrl from '../Api/api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const AdminLogin = () => {

    const navigate = useNavigate();


    const [formData, setFormData] = useState({
        username: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        const url = `${apiUrl}/auth/admin/login?username=${formData.username}&password=${formData.password}`;

        axios.get(url, { withCredentials: true })
            .then((response) => {
                navigate("/admin-dashboard");
            })
            .catch((error) => {
                console.log('Login Failed');
            })

    };

    return (
        <ThemeProvider theme={theme}>

            <Container maxWidth="xs">
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{
                        mt: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 2,
                    }}
                >
                    <Typography component="h1" variant="h5">
                        Admin Login
                    </Typography>
                    <TextField
                        label="Username"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <TextField
                        label="Password"
                        name="password"
                        type="password"
                        value={formData.password}
                        onChange={handleChange}
                        variant="outlined"
                        fullWidth
                        required
                    />
                    <Button type="submit" variant="contained" fullWidth sx={{ color: "white", fontSize: "1rem", fontWeight: "bold" }}>
                        Login
                    </Button>
                </Box>
            </Container>


        </ThemeProvider>
    );
};

export default AdminLogin;
