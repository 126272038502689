import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import {
    Box,
    Typography,
    Container,
    Paper,
    Divider,
} from '@mui/material';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import theme from '../../components/Theme';

const PrivacyPolicy = () => {
    return (
        <ThemeProvider theme={theme}>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <Header />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4, p: 3 }}>
                <Typography variant="h5" gutterBottom align="center" sx={{ fontWeight: 'bold' }}>
                    Privacy Policy
                </Typography>
                <Paper elevation={3} sx={{ p: 3 }}>
                    <Typography variant="body1" paragraph>
                        This website is controlled and maintained by Goveda Foundation. If you would like to provide feedback regarding the website, please email us at <a href="mailto:info@govedafoundation.org">info@govedafoundation.org</a>.
                    </Typography>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Information Collected
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We collect information from you when you subscribe to our newsletter or submit a donation. When donating or registering on our site, as appropriate, you may be asked to enter certain personal details such as your name, occupation, address, e-mail address, phone number etc. or financial details such as bank account or credit card or debit card or other payment instrument details. You may, however, also visit our site anonymously. By providing us with the above information, you consent to authorize us to use such information in the manner and for the purposes prescribed in this Privacy Policy.
                    </Typography>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h5" component="h2" gutterBottom>
                        Use of Information Collected
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Any of the information we collect from you may be used in any one or more of the following ways:
                    </Typography>
                    <ul>
                        <li>
                            <Typography variant="body1" component="span">To process donations.</Typography>
                        </li>
                        <li>
                            <Typography variant="body1" component="span">
                                Your information, whether public or private, will not be sold, exchanged, transferred, or given to any other person for any reason whatsoever, without your consent.
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1" component="span">
                                To send periodic emails. The email address you provide for donation/order processing may be used to send you information and updates pertaining to the same. In addition, if you have selected the option to receive our newsletter, you will receive occasional news, updates, related information, etc. If at any time you would like to unsubscribe from receiving future emails, we include detailed unsubscribe instructions at the bottom of each email.
                            </Typography>
                        </li>
                    </ul>
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h5" component="h2" gutterBottom>
                        Protection of Information
                    </Typography>
                    <Typography variant="body1" paragraph>
                        As per Indian law, Goveda Foundation is required to comply with reasonable security practices and procedures which include a comprehensive documented information security program and information security policy that contain managerial, technical, operational, and physical security control measures. We implement a variety of security measures to maintain the safety of your personal information and sensitive personal information or data provided to us, subject to applicable law (Information Technology Act and its corresponding rules). Please note that GOVEDA FOUNDATION complies with globally accepted security practices and norms regarding the protection of information of the users.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        We use secure servers for our financial transactions, including when you make a donation. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential. After a transaction, your private information (credit cards, social security numbers, financials, etc.) will not be stored on our servers.
                    </Typography>
                    {/* Additional Sections */}
                    {/* You can continue adding the remaining sections here using the same pattern */}
                    <Divider sx={{ my: 2 }} />
                    <Typography variant="h5" component="h2" gutterBottom>
                        Grievance Cell
                    </Typography>
                    <Typography variant="body1" paragraph>
                        In case of any grievance or complaints, the grievance officer may be contacted: Email: <a href="mailto:info@govedafoundation.org">info@govedafoundation.org</a>
                    </Typography>
                </Paper>
            </Container>
            <Footer />
        </ThemeProvider>
    );
};

export default PrivacyPolicy;
