import React, { useState } from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import { Box, Typography, Grid, Paper, Container, Stack } from '@mui/material';

const About = () => {

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div >
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />
                <Container sx={{ mt: 2 }}>
                    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEu6-A0N8KC3QPP_oBiuqa-JVic3tU90M_vnP6YvzoFNAGlfZOlLhS_hmr3cfQzmYw6A&usqp=CAU' width="100%" height={150} />
                </Container>
                <Box sx={{ mt: 2, p: { xs: 3, md: 5 } }}>
                    {/* Header Section */}
                    <Box textAlign="center" mb={5}>
                        <Typography variant="h4" component="h1" gutterBottom>
                            About GoVeda
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            GoVeda Foundation is a Public Religious Trust registered at Chennai, Tamil Nadu, India.
                        </Typography>
                        <Typography variant="body1" color="textSecondary" sx={{ mt: 2 }}>
                            The Objectives of the foundation can be broadly classified under two heads: Physical Well Being and Mental Well Being, represented by “GO” and “VEDA” respectively.
                        </Typography>
                    </Box>

                    {/* GO Objective Section */}
                    <Box mb={{ xs: 5, md: 10 }}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={6} alignItems="center">
                            {/* Image on the Left */}
                            <Paper elevation={1} sx={{ p: 2, }}>
                                <img
                                    src="https://picsum.photos/id/400/300/300"
                                    loading='lazy'
                                    alt="Go Objective"
                                    style={{
                                        // width: "200px",
                                        // height: '200px',
                                        borderRadius: '8px',
                                        objectFit: "cover"
                                    }}
                                />
                            </Paper>

                            {/* Text on the Right */}
                            <Box flex="1">
                                <Typography variant="h5" component="h2" gutterBottom>
                                    The Go Objective
                                </Typography>
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    The GO objectives of the foundation focus on preservation and propagation of Indian traditional agricultural and farming practices to present and next generation farmers, No cost Go Samrakshana, and other charitable activities.
                                </Typography>

                                {/* Objectives List */}
                                <ul style={{ paddingLeft: '20px', marginTop: '16px' }}>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Set up a Model Zero Budget Natural Farms (ZBNF) and Training Centre.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Provide technical training and support to next generation farmers for setting up Natural Farms at Zero Budget.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Educate students through experiential learning in farming.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Create and Maintain an Online Marketplace for Naturally grown farm and agro products.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Go samrakshana – Preservation of cows & cattle at no cost through integrated farming techniques.</Typography>
                                    </li>
                                </ul>
                            </Box>
                        </Stack>
                    </Box>


                    {/* VEDA Objective Section */}
                    <Box mb={10}>
                        <Stack direction={{ xs: "column", md: "row" }} spacing={6} alignItems="center">

                            {/* Text on the Right */}
                            <Box flex="1">
                                <Typography variant="h5" component="h2" gutterBottom>
                                    The Go Objective
                                </Typography>
                                <Typography variant="body1" color="textSecondary" gutterBottom>
                                    The GO objectives of the foundation focus on preservation and propagation of Indian traditional agricultural and farming practices to present and next generation farmers, No cost Go Samrakshana, and other charitable activities.
                                </Typography>

                                {/* Objectives List */}
                                <ul style={{ paddingLeft: '20px', marginTop: '16px' }}>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Set up a Model Zero Budget Natural Farms (ZBNF) and Training Centre.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Provide technical training and support to next generation farmers for setting up Natural Farms at Zero Budget.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Educate students through experiential learning in farming.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Create and Maintain an Online Marketplace for Naturally grown farm and agro products.</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1" color="textSecondary">Go samrakshana – Preservation of cows & cattle at no cost through integrated farming techniques.</Typography>
                                    </li>
                                </ul>
                            </Box>

                            {/* Image on the Left */}
                            <Paper elevation={1} sx={{ p: 2 }}>
                                <img
                                    src="https://picsum.photos/id/400/300/300"
                                    alt="Go Objective"
                                    loading='lazy'
                                    style={{
                                        // width: "200px",
                                        // height: '200px',
                                        borderRadius: '8px',
                                        objectFit: "cover"
                                    }}
                                />
                            </Paper>
                        </Stack>
                    </Box>

                </Box >

                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default About;