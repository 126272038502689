import React from 'react';
import { ThemeProvider } from '@emotion/react';
import ScrollToTop from 'react-scroll-to-top';
import Header from '../components/Header';
import Footer from '../components/Footer';
import theme from '../components/Theme';
import { Box, Typography, Grid, Container, Paper, Button } from '@mui/material';

const DonationPage = () => {
    return (
        <div>
            <ScrollToTop
                smooth
                component={
                    <svg
                        fill="#ffffff"
                        height="20px"
                        width="20px"
                        viewBox="0 0 512.02 512.02"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M505.762,240.923L271.095,6.256c-8.341-8.341-21.824-8.341-30.165,0L6.263,240.923c-4.011,3.989-6.251,9.408-6.251,15.083v234.667c0,8.619,5.184,16.427,13.163,19.712c7.979,3.328,17.152,1.493,23.253-4.629l219.584-219.584l219.584,219.584c4.075,4.075,9.536,6.251,15.083,6.251c2.752,0,5.525-0.512,8.171-1.621c7.979-3.285,13.163-11.093,13.163-19.712V256.005C512.013,250.331,509.773,244.912,505.762,240.923z" />
                    </svg>
                }
            />
            <ThemeProvider theme={theme}>
                <Header />
                <Container maxWidth="lg">
                    <Box sx={{ py: { xs: 7, md: 10 } }}>
                        <Grid container spacing={4}>
                            {/* Left Section: QR Code */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ p: 4, backgroundColor: '#f5f5f5' }}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{ color: '#318065', fontWeight: 'bold' }}
                                    >
                                        Scan the QR code to donate
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            py: 5,
                                            border: '2px solid #ddd',
                                            borderRadius: '8px',
                                            backgroundColor: '#fff',
                                        }}
                                    >
                                        <img
                                            src={require("../images/QR-Code.jpg")}
                                            alt="QR Code"
                                            loading='lazy'
                                            style={{ maxWidth: '100%', height: 'auto' }}
                                        />
                                    </Box>
                                </Paper>
                            </Grid>

                            {/* Right Section: Bank Details */}
                            <Grid item xs={12} md={6}>
                                <Paper elevation={3} sx={{ p: 4, backgroundColor: '#f5f5f5' }}>
                                    <Typography
                                        variant="h4"
                                        gutterBottom
                                        sx={{ color: '#318065', fontWeight: 'bold' }}
                                    >
                                        (Or) Transfer funds via NEFT/RTGS
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        <strong>Account Name:</strong> GoVeda Foundation
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        <strong>Account Number:</strong> 500101011766197
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        <strong>Bank Name:</strong> City Union Bank
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        <strong>Branch:</strong> Mandaveli
                                    </Typography>
                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        <strong>IFSC:</strong> CIUB0000036
                                    </Typography>

                                    <Box sx={{ my: 3 }}>
                                        <hr />
                                    </Box>

                                    <Typography variant="h4" gutterBottom sx={{ color: '#318065', fontWeight: 'bold' }}>
                                        Send details to raise a receipt:
                                    </Typography>

                                    <Typography variant="body1" sx={{ color: '#333', mb: 2 }}>
                                        Kindly send the following details to{' '}
                                        <a href="mailto:donate@govedafoundation.org" style={{ color: '#4b769f', fontWeight: 'bold' }}>
                                            donate@govedafoundation.org
                                        </a>:
                                    </Typography>

                                    <Box sx={{ pl: 3 }}>
                                        <ul style={{ paddingLeft: '1em', color: '#333' }}>
                                            <li><Typography variant="body1">Name</Typography></li>
                                            <li><Typography variant="body1">Address</Typography></li>
                                            <li><Typography variant="body1">Email</Typography></li>
                                            <li><Typography variant="body1">Phone</Typography></li>
                                            <li><Typography variant="body1">Transaction ID / Screenshot</Typography></li>
                                        </ul>
                                    </Box>

                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Call to Action Section */}
                    {/* <Box sx={{ display: 'flex', justifyContent: 'center', my: 5 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{ backgroundColor: '#4b769f', padding: '10px 20px', fontSize: '1.2rem' }}
                        >
                            Donate Now
                        </Button>
                    </Box> */}
                </Container>
                <Footer />
            </ThemeProvider>
        </div>
    );
};

export default DonationPage;
