import React, { useState } from 'react';
import {
    AppBar, Toolbar, Typography, Box, Drawer,
    IconButton, useMediaQuery, useTheme, ListItem, ListItemText, List, Button, Collapse,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Menu, MenuItem, } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


const AdminHeader = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md')); // 992px

    const [drawerOpen, setDrawerOpen] = useState(false);


    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };



    const [anchorEl, setAnchorEl] = useState(null);

    const handleMouseEnter = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleDropdownClick = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <Box>
            {/* Header Row 1 */}

            {
                isSmallScreen &&

                (
                    <AppBar position="static" sx={{ backgroundColor: 'white', p: 2 }}>
                        <Box sx={{ width: isSmallScreen ? "100%" : "", display: "flex", justifyContent: isSmallScreen ? "space-between" : '' }}>
                            <img src={require("../images/goveda-logo.png")} height={50} />
                            {
                                isSmallScreen &&
                                <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleDrawerOpen}>
                                    <MenuIcon sx={{ color: "black" }} />
                                </IconButton>
                            }
                        </Box>
                    </AppBar>

                )
            }


            {/* Header Row 2 */}
            <AppBar position={isSmallScreen ? "static" : "fixed"} sx={{ backgroundColor: theme.palette.primary.main, p: 2 }}>
                <Toolbar>
                    <Typography gutterBottom variant='h6' sx={{ fontWeight: "bold", textAlign: "center" }}>Admin Dashboard</Typography>



                    {!isSmallScreen && (
                        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                component={Link}
                                to="/admin-dashboard"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Dashboard
                            </Button>
                            <Button
                                component={Link}
                                to="/admin-total-donors"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Donors
                            </Button>
                            <Button
                                component={Link}
                                to="/admin-payment-operation"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Payment Link
                            </Button>
                            <Button
                                component={Link}
                                to="/gallery"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Gallery
                            </Button>
                            <Button
                                component={Link}
                                to="/contact"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Contact
                            </Button>

                            <div className="navbar">
                                <div className="dropdown">
                                    <button className="dropbtn">Show All Menu <ArrowDropDownIcon /></button>
                                    <div className="dropdown-content">
                                        <div className="mega-dropdown">
                                            <div className="column">
                                                <h3>Category 1</h3>
                                                <a href="#link1">Link 1</a>
                                                <a href="#link2">Link 2</a>
                                                <a href="#link3">Link 3</a>
                                                <a href="#link1">Link 1</a>
                                                <a href="#link2">Link 2</a>
                                                <a href="#link3">Link 3</a>
                                            </div>
                                            <div className="column">
                                                <h3>Category 2</h3>
                                                <a href="#link4">Link 4</a>
                                                <a href="#link5">Link 5</a>
                                            </div>
                                            <div className="column">
                                                <h3>Category 3</h3>
                                                <a href="#link6">Link 6</a>
                                                <a href="#link7">Link 7</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <Button
                                component={Link}
                                to="/admin-receipt-creation"
                                sx={{
                                    textDecoration: 'none',
                                    color: '#fff',
                                    mx: 1,
                                    fontWeight: "bold",
                                    '&:hover': {
                                        backgroundColor: '#28ab76', // Dark green color on hover
                                    },
                                }}
                                variant="text"
                            >
                                Download Receipt
                            </Button>

                        </Box>

                    )}
                </Toolbar>
            </AppBar>

            {/* Drawer */}
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={handleDrawerClose}
                PaperProps={{
                    style: {
                        width: "80%", // Adjust width as needed
                    },
                }}
            >
                <List sx={{ fontWeight: "900" }}>
                    <img src={require("../images/goveda-logo.png")} height={50} style={{ margin: "20px" }} />
                    <br />
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/admin-dashboard" onClick={handleDrawerClose}>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/admin-total-donors" onClick={handleDrawerClose}>
                        <ListItemText primary="Donors" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/admin-payment-operation" onClick={handleDrawerClose}>
                        <ListItemText primary="Payment Link" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/gallery" onClick={handleDrawerClose}>
                        <ListItemText primary="Gallery" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} onClick={handleDropdownClick}>
                        <ListItemText primary="Causes" />
                        {dropdownOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={dropdownOpen} timeout="auto" unmountOnExit sx={{ mr: 1 }}>
                        <List component="div" disablePadding>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option1" onClick={handleDrawerClose}>
                                <ListItemText primary="Agnihotra Vruddhi" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option2" onClick={handleDrawerClose}>
                                <ListItemText primary="Annadanam" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option3" onClick={handleDrawerClose}>
                                <ListItemText primary="Support Patashalas" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option4" onClick={handleDrawerClose}>
                                <ListItemText primary="Vaiddeehal Matrimony" />
                            </ListItem>
                            <ListItem sx={{ color: "#187a43d6", borderBottom: "1px solid #eee", backgroundColor: "#eeeeee7a" }} component={Link} to="/option5" onClick={handleDrawerClose}>
                                <ListItemText primary="Village Temple Parayanam" />
                            </ListItem>
                        </List>
                    </Collapse>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/contact" onClick={handleDrawerClose}>
                        <ListItemText primary="Contact" />
                    </ListItem>
                    <ListItem sx={{ color: "#187a43", borderBottom: "1px solid #eee" }} component={Link} to="/admin-receipt-creation" onClick={handleDrawerClose}>
                        <ListItemText primary="Download Receipt" />
                    </ListItem>
                </List>

            </Drawer>
        </Box>
    );
};

export default AdminHeader;
