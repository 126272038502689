import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#10cb7f', // Primary color
        },
        secondary: {
            main: '#ff6d0d', // Secondary color
        },
    },
});

export default theme;
